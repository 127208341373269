import "chartjs-plugin-zoom";
import {
  groupBy,
  map,
  maxBy,
  minBy,
  orderBy,
  reverse,
  sumBy,
  takeRight
} from "lodash";
import React from "react";
import TableData from "../../common/components/layout/Table/TableData";
import TableHeader from "../../common/components/layout/Table/TableHeader";
import { numberWithCommas } from "../../common/utils";
import { FuelHistoryAsset } from "../../livestock-activity/graphql";
import { FuelAsset } from "../graphql";
import FuelHistoryChart from "./FuelHistoryChart";
import Divider from "../../common/components/layout/Divider";
interface FuelHistoryProps {
  fuelAsset: FuelAsset;
  fuelHistoryAsset: FuelHistoryAsset[];
  fuelMeasurementUnitOfMeasurementLabel: string
}

const FuelHistoryView = React.forwardRef<HTMLElement, FuelHistoryProps>(
  function FuelHistoryView({ fuelHistoryAsset, fuelAsset, fuelMeasurementUnitOfMeasurementLabel }, ref) {
    const { unitOfMeasureCode } = fuelAsset;

    const sortedList = orderBy(fuelHistoryAsset, ["postingDate"], ["desc"]);

    const calcRate = (
      asset: { meta: number; quantity: number },
      i: number
    ): number => {
      const { meta: m, quantity: q } = asset;
      if (!m || !q || i === sortedList.length - 1) return 0;
      if (unitOfMeasureCode === "HOUR") {
        return parseFloat((q / (m - sortedList[i + 1].meta)).toFixed(1));
      } else {
        return parseFloat(((m - sortedList[i + 1].meta) / q).toFixed(1));
      }
    };

    const calcAveRate = (asset: { meta: number; quantity: number }): number => {
      const { meta: m, quantity: q } = asset;
      if (unitOfMeasureCode === "HOUR") {
        return parseFloat((q / m).toFixed(1));
      } else {
        return parseFloat((m / q).toFixed(1));
      }
    };

    const getUoM = (calc: number) => {
      switch (unitOfMeasureCode.toUpperCase()) {
        case "MILES":
        case "MILE":
          return `${calc} mpg`;
        case "HOURS":
        case "HOUR":
          return `${calc} gph`;
        default:
          return "---";
      }
    };

    if (!fuelAsset || !fuelHistoryAsset || !fuelHistoryAsset[0]) {
      return <h1>No data found.</h1>;
    }

    const TotalsRow = () => {
      const m = sortedList;
      const arr = groupBy(m, a => a.postingDate.split("-")[0]);

      const sums = takeRight(
        map(arr, (v, k) => {
          return {
          key: k,
          // eslint-disable-next-line
          milesHoursSum: (maxBy(v, "meta")?.meta || 0) - (minBy(v, "meta")?.meta || 0),
          totalCost: `$${numberWithCommas(sumBy(v, "amount").toFixed())}`,
          fuelSum: `${numberWithCommas(sumBy(v, "quantity").toFixed())} gal`,
          aveFuelCost: `$${(sumBy(v, "amount")/sumBy(v, "quantity")).toFixed(2)}/gal`,
          efficiencySum: `${getUoM(
            calcAveRate({
              // eslint-disable-next-line
              meta: ((maxBy(v, "meta")?.meta || 0) - (minBy(v, "meta")?.meta || 0)),
              quantity: sumBy(v, "quantity")
            })
          )}`
        }}),
        3
      );

      return (
        <ul
          role="list"
          className="grid grid-cols-1 gap-x-2 gap-y-8 xs:grid-cols-3"
        >
          {reverse(sums).map((t, i) => (
            <li
              className="overflow-hidden rounded-xl border border-gray-200 gap-x-2"
              key={i}
            >
              <div className="flex items-center border-b border-gray-900/5 bg-gray-50 p-2">
                <div className="text-sm font-medium leading-6 text-gray-900">
                  {t.key}
                </div>
              </div>
              <dl className="-my-3 divide-y divide-gray-100 px-2 py-2 text-sm mt-1">
                <div className="flex justify-between">
                  <dt className="text-gray-500">Total</dt>
                  <dd className="text-gray-700 ">{t.totalCost}</dd>
                </div>
              </dl>
              <dl className="-my-3 divide-y divide-gray-100 px-2 py-2 text-sm mt-1">
                <div className="flex justify-between">
                  <dt className="text-gray-500">{fuelMeasurementUnitOfMeasurementLabel}</dt>
                  <dd className="text-gray-700 ">{t.milesHoursSum}</dd>
                </div>
              </dl>
              <dl className="-my-3 divide-y divide-gray-100 px-2 py-2 text-sm">
                <div className="flex justify-between">
                  <dt className="align-items-left text-gray-500">Quant.</dt>
                  <dd className="text-right text-gray-700 ">{t.fuelSum}</dd>
                </div>
              </dl>
              <dl className="-my-3 divide-y divide-gray-100 px-2 py-2 text-sm mt-1">
                <div className="flex justify-between">
                  <dt className="text-gray-500">Cost</dt>
                  <dd className="text-gray-700 ">{t.aveFuelCost}</dd>
                </div>
              </dl>
              <dl className="-my-3 divide-y divide-gray-100 px-2 py-2 text-sm mb-1">
                <div className="flex justify-between">
                  <dt className="text-gray-500">Eff.</dt>
                  <dd className="text-gray-700 ">{t.efficiencySum}</dd>
                </div>
              </dl>
            </li>
          ))}
        </ul>
      );
    };

    return (
      <div className="pt-3 overflow-y-auto">
        <Divider centerText="Summary" className="p-3"/>
        <div className="sticky top-0 w-full bg-white">
          <TotalsRow />
        </div>
        <Divider centerText="Data" className="p-3"/>
        <div className="overflow-x-auto">
          <table className="divide-y divide-gray-200 box-shadow min-w-full">
            <thead>
              <tr>
                <TableHeader children="Date" />
                <TableHeader children="Amount" />
                <TableHeader children="Quantity" />
                <TableHeader children="Efficiency" />
                <TableHeader children="Odometer" />
                <TableHeader children="Delta" />
                <TableHeader children="Comments" />
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {sortedList.map((asset: FuelHistoryAsset, index: number) => (
                <tr key={asset.postingDate}>
                  <TableData children={asset.postingDate} />
                  <TableData children={`$${asset.amount}`} />
                  <TableData children={`${asset.quantity} gal`} />
                  <TableData children={getUoM(calcRate(asset, index))} />
                  <TableData children={`${asset.meta}`} />
                  <TableData
                    children={`${
                      sortedList[index + 1]
                        ? asset.meta - sortedList[index + 1].meta
                        : "---"
                    }`}
                  />
                  <TableData children={`${asset.description}`} />
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <Divider centerText="Trend" className="p-3"/>
        <div className="overflow-x-scroll overflow-y-hidden px-3 max-w-4xl flex mx-auto">
          <FuelHistoryChart
            unitOfMeasureCode={fuelAsset.unitOfMeasureCode}
            fuelHistoryAsset={fuelHistoryAsset}
            calcRate={calcRate}
          />
        </div>
      </div>
    );
  }
);

export default FuelHistoryView;
